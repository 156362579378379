import { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Card } from 'antd';
import { Link } from 'react-router-dom';
import { DualAxes, Bar, Pie, Line, Column, Funnel } from '@ant-design/plots';


const mapStateToProps = (state) => ({

})

const LandingPage2 = ({
}) => {



    const data = [
        {
            year: 'Jan',
            2022: 10,
            2021: 15,
        },
        {
            year: 'Feb',
            2022: 70,
            2021: 65,
        },
        {
            year: 'Mar',
            2022: 45,
            2021: 55,
        },
        {
            year: 'Apr',
            2022: 35,
            2021: 35,
        },
        {
            year: 'May',
            2022: 10,
            2021: 9,
        },
        {
            year: 'June',
            2022: 25,
            2021: 25,
        },
        {
            year: 'July',
            2022: 47,
            2021: 57,
        },
        {
            year: 'Aug',
            2022: 65,
            2021: 60,
        },
        {
            year: 'Sep',
            2022: 35,
            2021: 20,
        },
        {
            year: 'Oct',
            2022: 90,
            2021: 88,
        },
        {
            year: 'Nov',
            2022: 12,
            2021: 12,
        },
        {
            year: 'Dec',
            2022: 18,
            2021: 20,
        },
    ];

    const config1 = {
        data: [data, data],
        xField: 'year',
        yField: ['2022', '2021'],
        legend: {
            position: 'bottom'
        },
        geometryOptions: [
            {
                geometry: 'line',
                smooth: false,
                color: '#5B8FF9',
                lineStyle: {
                    lineWidth: 3,
                    lineDash: [5, 5],
                },
            },
            {
                geometry: 'line',
                smooth: true,
                color: '#5AD8A6',
                lineStyle: {
                    lineWidth: 4,
                    opacity: 0.5,
                },
                point: {
                    shape: 'circle',
                    size: 4,
                    style: {
                        opacity: 0.5,
                        stroke: '#5AD8A6',
                        fill: '#fff',
                    },
                },
            },
        ],
    };

    const compliancedata = [
        { "city": "India", "type": "Complied", "value": 180 },
        { "city": "India", "type": "Delayed Compliance", "value": 85 },
        { "city": "India", "type": "In Progress", "value": 200 },
        { "city": "India", "type": "Not Complied", "value": 50 },
        { "city": "Srilanka", "type": "Complied", "value": 90 },
        { "city": "Srilanka", "type": "Delayed Compliance", "value": 58 },
        { "city": "Srilanka", "type": "In Progress", "value": 120 },
        { "city": "Srilanka", "type": "Not Complied", "value": 80 },
        { "city": "Australia", "type": "Complied", "value": 160 },
        { "city": "Australia", "type": "Delayed Compliance", "value": 60 },
        { "city": "Australia", "type": "In Progress", "value": 180 },
        { "city": "Australia", "type": "Not Complied", "value": 95 },
    ]
    const complianceconfig = {
        data: compliancedata,
        xField: 'city',
        yField: 'value',
        seriesField: 'type',
        isGroup: 'true',
        legend: {
            position: 'bottom', offsetX: 8,
            title: { text: 'Country Wise Compliance', spacing: 8 },
            itemValue: {
                formatter: (text, item) => {
                    const items = data.filter((d) => d.type === item.value);
                    return items.length ? items.reduce((a, b) => a + b.value, 0) / items.length : ''
                },
                style: { opacity: 0.65, },
            },
        }, color: ['#90EE90', '#0096FF', '#FFFF00', '#EE4B2B']
    };

    const taskdata = [
        {
            type: 'Assigned',
            value: 27,
        },
        {
            type: 'Not Opted',
            value: 25,
        },
        {
            type: 'Unassigned',
            value: 18,
        },
        {
            type: 'Not Applicable',
            value: 15,
        }
    ];

    const taskconfig = {
        appendPadding: 10,
        data: taskdata,
        angleField: 'value',
        colorField: 'type',
        radius: 0.75,
        label: {
            type: 'spider',
            labelHeight: 28,
            content: '{name}\n{percentage}',
        },
        legend: {
            position: 'bottom'
        },
        interactions: [
            {
                type: 'element-selected',
            },
            {
                type: 'element-active',
            },
        ],
        color: ['#2FC6F6', '#F31126', '#F7A872', '#BFC5C6']
    };

    const overduedata = [
        {
            year: '0-30 days',
            value: 50,
        },
        {
            year: '31-60 days',
            value: 97,
        },
        {
            year: '61-90 days',
            value: 212,
        },
        {
            year: 'Above 90 days',
            value: 404,
        }
    ];

    const overdueconfig = {
        data: overduedata,
        xField: 'value',
        yField: 'year',
        seriesField: 'year',
        legend: {
            position: 'bottom',
        },
        color: ['#F8BD8C', '#F47C5E', '#FA9D0D', '#F162B0']
    };

    const riskdata = [
        {
            label: 'Not Opted',
            type: 'Not Opted',
            value: 308,
        },
        {
            label: 'Unassigned',
            type: 'Unassigned',
            value: 3000,
        },
        {
            label: 'Not Applicable',
            type: 'Not Applicable',
            value: 520,
        },
    ];

    const riskconfig = {
        data: riskdata,
        isGroup: true,
        xField: 'value',
        yField: 'label',
        seriesField: 'type',
        marginRatio: 5,
        label: {
            position: 'middle',
            layout: [
                {
                    type: 'interval-adjust-position',
                },
                {
                    type: 'interval-hide-overlap',
                },
                {
                    type: 'adjust-color',
                },
            ],
        },
        legend: {
            position: 'bottom'
        },
        color: ['#F71126', '#FCBD89', '#919393']
    };
    const riskexposeddata = [
        {
            label: 'Not Complied',
            type: 'Not Opted',
            value: 450,
        },
        {
            label: 'Delayed Compliance',
            type: 'Delayed Compliance',
            value: 650,
        }
    ];

    const riskexposedconfig = {
        data: riskexposeddata,
        isGroup: true,
        xField: 'value',
        yField: 'label',
        seriesField: 'type',
        marginRatio: 5,
        label: {
            position: 'middle',
            layout: [
                {
                    type: 'interval-adjust-position',
                },
                {
                    type: 'interval-hide-overlap',
                },
                {
                    type: 'adjust-color',
                },
            ],
        },
        legend: {
            position: 'bottom'
        },
        color: ['#FA4E97', '#0096FF']
    };

    const criticalitydata = [
        {
            stage: 'High',
            number: 745,
        },
        {
            stage: 'Medium',
            number: 351,
        },
        {
            stage: 'Low',
            number: 213,
        }
    ];

    const criticalityconfig = {
        data: criticalitydata,
        xField: 'stage',
        yField: 'number',
        legend: {
            position: 'bottom'
        },
        color: ['#D50B53', '#A882C1', '#824CA7']
    };

    const complianceTrend = [
        { "year": "2018", "value": 35, "type": "Complied" },
        { "year": "2019", "value": 45, "type": "Complied" },
        { "year": "2020", "value": 25, "type": "Complied" },
        { "year": "2021", "value": 55, "type": "Complied" },
        { "year": "2022", "value": 49, "type": "Complied" },
        { "year": "2018", "value": 31, "type": "Delayed Compliance" },
        { "year": "2019", "value": 42, "type": "Delayed Compliance" },
        { "year": "2020", "value": 35, "type": "Delayed Compliance" },
        { "year": "2021", "value": 51, "type": "Delayed Compliance" },
        { "year": "2022", "value": 45, "type": "Delayed Compliance" },
        { "year": "2018", "value": 25, "type": "Not Complied" },
        { "year": "2019", "value": 34, "type": "Not Complied" },
        { "year": "2020", "value": 46, "type": "Not Complied" },
        { "year": "2021", "value": 27, "type": "Not Complied" },
        { "year": "2022", "value": 49, "type": "Not Complied" }
    ]
    const compliancetrendconfig = {
        data: complianceTrend, isStack: true, xField: 'year', yField: 'value', seriesField: 'type', label: {
            position: 'middle',
        }, 
        interactions: [{ type: 'active-region', enable: false, },], 
        columnBackground: { style: { fill: 'rgba(0,0,0,0.1)', }, },
        legend : {
            position : 'bottom'
        }
    }

    return (
        <div className="page-wrapper" >
            <div className="page-titles pb-2 pt-2">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                        </nav>
                    </div>
                </div>
                <div className="container-fluid pt-1">
                    <div className="row">
                        <div className='col-12'>
                            <div className='row'>
                                <div className="col-lg-6">
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">Compliance Status</h3>
                                        </div>
                                        <div className="card-body">
                                            <Column {...complianceconfig} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="card" >
                                        <div className="card-header">
                                            <h3 className="card-title">
                                                Comparitive Trend
                                            </h3>
                                        </div>
                                        <div className='row'>
                                            <div className="card-body">
                                                <DualAxes {...config1} />
                                                {/* <div id="area-chart"></div> */}
                                            </div>
                                            {/* <div className='col-lg-4' style={{ marginLeft: '50%', color: 'green' }}>
                                                <span>4% More than Previous</span>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='row'>
                                <div className='col-7'>
                                    <div className='card'>
                                        <div className="card-header mb-3">
                                            <h3 className="card-title">
                                                Task Distribution
                                            </h3>
                                        </div>
                                        <div className='card-body'>
                                            <Pie {...taskconfig} />;
                                        </div>
                                    </div>
                                </div>
                                <div className='col-5'>
                                    <div className="card" style={{height:'560px'}}>
                                        <div className="card-header">
                                            <h3 className="card-title">
                                                Over Due Compliance
                                            </h3>
                                        </div>
                                        <div className="card-body">
                                            <Bar {...overdueconfig} />;
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='row'>
                                <div className='col-6'>
                                    <div className='card' style={{ height: "540px" }}>
                                        <div className='card-header'>
                                            <h5 className='card-title'>
                                                Criticality Chart
                                            </h5>
                                        </div>
                                        <div className="card-body">
                                            <Funnel {...criticalityconfig} columnWidthRatio={1.5} />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className="card"  style={{height:'540px'}}>
                                        <div className="card-header">
                                            <h3 className="card-title">Compliance Trend</h3>
                                        </div>
                                        <div className="card-body">
                                            <Column {...compliancetrendconfig} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-12'>
                            <div className='card'>
                                <div className="card-header mb-3">
                                    <h3 className="card-title">
                                        <span>Risk Chart- Hidden Risk</span>
                                        <span style={{ marginLeft: '380px' }}>Risk Chart- Exposed Risk</span>
                                    </h3>
                                </div>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <Bar {...riskconfig} width={300} height={200} barWidthRatio={2.5} />
                                        </div>
                                        <div className='col-6'>
                                            <Bar {...riskexposedconfig} width={300} height={200} barWidthRatio={1} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
export default connect(mapStateToProps, {
})(LandingPage2);